/**
 * JS Class that initializes an element that is passed into the constructor of the class.
 *
 * Usage:
 * import { DatatableInit } from '../custom_functions/datatable_init.js'
 *
 * new DatatableInit(element)
 */
import { DatatableAsyncPopulation } from '../custom_functions/datatable_async_population'

export class DatatableInit {
  constructor(element) {
    let tableId = $(element).find('table').attr('id') || '';
    let pageLength = localStorage.getItem(`${tableId}pageLength`) || 10;
    let sort = element.dataset['sort'] || false
    let buttons = JSON.parse(element.dataset['buttons'] || '[]');
    let ajaxUrlPath = element.dataset['ajaxUrlPath'];
    let pdf = element.dataset['pdf'] === 'true';
    let columnDefs = []

    $(element).find('tr.sorting-columns th').each(function(index, column){
      let type = column.dataset['columnDefs']
      if (type)
        columnDefs.push({type: type, targets: index})
    });

    $.fn.dataTable.moment( 'MMMM DD, YYYY' );
    $.fn.dataTable.Buttons.jszip(JSZip);
    let dom = "";
    if (pdf) {
      dom = "<'row'<'col-sm-12'tr>>";
    } else {
      dom = "<'row'<'col-6 d-flex justify-content-start' f><'col-6 d-flex justify-content-end text-sm-right' B>>" +
        "<'row'<'col-sm-12'tr>>i" +
        "<'row my-4'<'col-sm-4'l><'col-sm-8'p>>";
    }

    let dataTable = $(element).find('.table').DataTable({
      language: {
        paginate: {
          previous: "<i class='fa fa-angle-left'></i>",
          next: "<i class='fa fa-angle-right'></i>"
        }
      },
      responsive: true,
      sort: sort,
      pageLength: pdf ? -1 : parseInt(pageLength),
      lengthMenu: [
        [5, 10, 25, 50, 100, -1],
        [5, 10, 25, 50, 100, 'All'],
      ],
      columnDefs: columnDefs,
      buttons: buttons,
      dom: dom,
      initComplete: function () {
        var api = this.api();
        var table = this;

        // For each column
        api
          .columns()
          .eq(0)
          .each(function (colIdx) {
            // On every keypress in this input
            $(
              'input, select',
              $('.filters th', element).eq($(api.column(colIdx).header()).index()),
            )
              .off('keyup change')
              .on('change', function (e) {
                // Get the search value
                $(this).attr('title', $(this).val());
                var regexr = '({search})'; //$(this).parents('th').find('select').val();

                var cursorPosition = this.selectionStart;
                // Search the column for that value
                api
                  .column(colIdx)
                  .search(
                    this.value !== ''
                      ? regexr.replace('{search}', '(((' + this.value + ')))')
                      : '',
                    this.value !== '',
                    this.value === ''
                  )
                  .draw();
                calculateTotal(table);
              })
              .on('keyup', function (e) {
                e.stopPropagation();

                $(this).trigger('change');
                $(this).focus();
              });
          });

        calculateTotal(table);
      }
    });

    $('#' + tableId + '_length').on("change", 'select', function () {
      localStorage.setItem(`${tableId}pageLength`, $(this).val());
    });

    if (ajaxUrlPath) {
      $(element).removeClass('d-none');
      new DatatableAsyncPopulation(dataTable, ajaxUrlPath, tableId);
    }

    function calculateTotal(table) {
      if ($(table).find('tfoot.total-values')){
        let totalValues = {};
        $(table).find('tbody tr').each(function (index, tr) {
          let values = $(tr).find('[data-real-value]');
          values.each(function (i, td) {
            totalValues[td.cellIndex] ||= 0;
            totalValues[td.cellIndex] += Number.parseInt(td.dataset['realValue']);
          });
        });

        if ($.isEmptyObject(totalValues)) {
          $('.dataTables_scrollFoot table').hide();
        } else {
          $('.dataTables_scrollFoot table').show();
        }

        $.each(totalValues, function(k,v) {
          $($('.dataTables_scrollFoot table').find('tfoot tr th')[k]).html(number_to_human_size(v,1));
        });
      }
    }
  }
}
