import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    super.connect()

    let that = this;
    let item = that.element
    setTimeout(function(){that.initNavs(that)}, 100);

    window.addEventListener('resize', function(event) {

      item.querySelector('.moving-tab').remove();
      let moving_div = document.createElement('div');
      let tab = item.querySelector(".nav-link.active").cloneNode();
      tab.innerHTML = "-";

      moving_div.classList.add('moving-tab', 'position-absolute', 'nav-link');
      moving_div.appendChild(tab);

      item.appendChild(moving_div);

      moving_div.style.padding = '0px';
      moving_div.style.transition = '.5s ease';

      let li = item.querySelector(".nav-link.active").parentElement;

      if (li) {
        let nodes = Array.from(li.closest('ul').children); // get array
        let index = nodes.indexOf(li) + 1;

        let sum = 0;
        if (item.classList.contains('flex-column')) {
          for (let j = 1; j <= nodes.indexOf(li); j++) {
            sum += item.querySelector('li:nth-child(' + j + ')').offsetHeight;
          }
          moving_div.style.transform = 'translate3d(0px,' + sum + 'px, 0px)';
          moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
          moving_div.style.height = item.querySelector('li:nth-child(' + index + ')').offsetHeight;
        } else {
          for (let j = 1; j <= nodes.indexOf(li); j++) {
            sum += item.querySelector('li:nth-child(' + j + ')').offsetWidth;
          }
          moving_div.style.transform = 'translate3d(' + sum + 'px, 0px, 0px)';
          moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';

        }
      }

      if (window.innerWidth < 991) {
        if (!item.classList.contains('flex-column')) {
          item.classList.remove('flex-row');
          item.classList.add('flex-column', 'on-resize');
          let li = item.querySelector(".nav-link.active").parentElement;
          let nodes = Array.from(li.closest('ul').children); // get array
          // let index = nodes.indexOf(li) + 1;
          let sum = 0;
          for (let j = 1; j <= nodes.indexOf(li); j++) {
            sum += item.querySelector('li:nth-child(' + j + ')').offsetHeight;
          }

          moving_div.style.width = item.querySelector('li:nth-child(1)').offsetWidth + 'px';
          moving_div.style.transform = 'translate3d(0px,' + sum + 'px, 0px)';

        }

      } else {
        if (item.classList.contains('on-resize')) {
          item.classList.remove('flex-column', 'on-resize');
          item.classList.add('flex-row');
          let li = item.querySelector(".nav-link.active").parentElement;
          let nodes = Array.from(li.closest('ul').children); // get array
          let index = nodes.indexOf(li) + 1;
          let sum = 0;
          for (let j = 1; j <= nodes.indexOf(li); j++) {
            sum += item.querySelector('li:nth-child(' + j + ')').offsetWidth;
          }

          moving_div.style.transform = 'translate3d(' + sum + 'px, 0px, 0px)';
          moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
        }
      }
    });
    // // Function to remove flex row on mobile devices
    if (window.innerWidth < 991) {
      if (that.element.classList.contains('flex-row')) {
        that.element.classList.remove('flex-row');
        that.element.classList.add('flex-column', 'on-resize');
      }
    }
  }

  // Tabs navigation
  initNavs(that) {
    let element = that.element;
    let moving_div = document.createElement('div');
    let first_li = element.querySelector('li:first-child .nav-link');
    let tab = first_li.cloneNode();
    tab.innerHTML = "-";

    moving_div.classList.add('moving-tab', 'position-absolute', 'nav-link');
    moving_div.appendChild(tab);
    element.appendChild(moving_div);

    moving_div.style.padding = '0px';
    moving_div.style.width = element.querySelector('li:nth-child(1)').offsetWidth + 'px';
    moving_div.style.transform = 'translate3d(0px, 0px, 0px)';
    moving_div.style.transition = '.5s ease';

    element.onmouseover = function(event) {
      let target = that.getEventTarget(event);
      let li = target.closest('li'); // get reference
      if (li) {
        let nodes = Array.from(li.closest('ul').children); // get array
        let index = nodes.indexOf(li) + 1;
        element.querySelector('li:nth-child(' + index + ') .nav-link').onclick = function() {
          moving_div = element.querySelector('.moving-tab');
          let sum = 0;
          if (element.classList.contains('flex-column')) {
            for (let j = 1; j <= nodes.indexOf(li); j++) {
              sum += element.querySelector('li:nth-child(' + j + ')').offsetHeight;
            }
            moving_div.style.transform = 'translate3d(0px,' + sum + 'px, 0px)';
            moving_div.style.height = element.querySelector('li:nth-child(' + index + ')').offsetHeight;
          } else {
            for (let j = 1; j <= nodes.indexOf(li); j++) {
              sum += element.querySelector('li:nth-child(' + j + ')').offsetWidth;
            }
            moving_div.style.transform = 'translate3d(' + sum + 'px, 0px, 0px)';
            moving_div.style.width = element.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
          }
        }
      }
    }
  }

  getEventTarget(e) {
    e = e || window.event;
    return e.target || e.srcElement;
  }
}